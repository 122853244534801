import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"

const IndexPage = () => (
  <Layout>
    <Helmet
        title="CaringBand - A Wearable for Support, Encouragement and Love"
        meta={[
        {
            name: `description`,
            content: 'CaringBand enables anyone going through difficult times to have access to more encouragement. It\'s what we call Technology with Heart.',
        },
        {
            property: `og:title`,
            content: 'CaringBand - A Wearable for Support, Encouragement and Love',
        },
        {
            property: `og:description`,
            content: 'CaringBand enables anyone going through difficult times to have access to more encouragement. It\'s what we call Technology with Heart.',
        },
        {
            property: `og:type`,
            content: `website`,
        },
        {
            name: `twitter:card`,
            content: `summary`,
        },
        {
            name: `twitter:creator`,
            content: 'CaringBand, LLC',
        },
        {
            name: `twitter:title`,
            content: 'CaringBand - A Wearable for Support, Encouragement and Love',
        },
        {
            name: `twitter:description`,
            content: 'CaringBand enables anyone going through difficult times to have access to more encouragement. It\'s what we call Technology with Heart.',
        },
        ]}
    >
    </Helmet>
    <div className="container-fluid bg-primary text-white">
        <div className="container container-down">
            <p>To our cherished CaringBand community,</p>
            <p>As we navigate through the ebbs and flows of innovation and connectivity, we've been faced with the difficult decision to announce the conclusion of CaringBand's journey. Despite the passion and purpose that have fueled our platform, the realities of historically low usage and the significant costs of maintaining such a platform have led us to this moment of farewell.</p>
            <p>From the outset, CaringBand was more than just a technology platform—it was a dream to foster emotional support, joy, and encouragement among friends, families, and loved ones. Your stories of hope, connection, and support have been the heartbeat of CaringBand, inspiring us every step of the way. It has been our profound honor to witness the positive impact of our platform on the lives of so many.</p>
            <p>As we prepare to say goodbye, we want to express our deepest gratitude. Thank you for embracing CaringBand, for sharing your moments of joy and support, and for believing in the power of connection. Your trust and engagement have been our greatest rewards.</p>
            <p>We hope that CaringBand has been a source of light and comfort in your life, as your stories and experiences have been in ours. While the platform may be coming to an end, the spirit of CaringBand—encouragement, support, and love—will forever remain. We carry forward the lessons learned and the unforgettable memories made, hopeful that the essence of CaringBand will continue to inspire and uplift in new ways.</p>
            <p>Thank you for being an integral part of CaringBand's story. Your support, encouragement, and the joy you've shared have truly made a difference. Here's to the moments we've shared and to the hope that the spirit of CaringBand lives on in each of us.</p>
            <p>With heartfelt thanks and best wishes for the future,</p>
            <p>The CaringBand Team</p>
        </div>
    </div>
  </Layout>
)

export default IndexPage;
